import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import formatMoney from '@/helpers/formatMoney'
import { CSC_PAYMENTS_HOST } from '@/services/Configuration'
import { error, log } from '@/services/Log'
import type { SavedInstrument } from '@/types'

type Response = {
  method: string,
  amount: string,
  type: string
  mask: string
  instrument: null | SavedInstrument,
}

export const getPaymentMethodFromTransactionId = async (paymentId: null | string = '', paymentMethod = '', defaultCurrencyCode = '', orderTotal = ''): Promise<Response> => {
  log('getPaymentMethodFromTransactionId Start', {
    paymentId,
    paymentMethod,
    defaultCurrencyCode,
    orderTotal,
  })
  if (paymentMethod !== 'PayPal') {
    try {
      if (!paymentId) {
        log('No paymentId found. Either an order placed before starting to use CSC Payments or an error occurred.')
        throw new Error('No paymentId provided')
      }
      const [
        { Payments },
        { getSavedInstrumentsCredentials },
        { standardizeCardFromCscPayments },
      ] = await allPromisesWithRetries(() => [
        import('@csc/csc-sdk'),
        import('../paymentServices/cscPaymentsServices/getSavedInstrumentsCredentials'),
        import('@/services/Standardizers/checkout/standardizeCardFromCscPayments'),
      ])
      const credentials = await getSavedInstrumentsCredentials()
      const identity = credentials.identitySourceId
      const CSCPaymentPortal = Payments({ credentials, host: CSC_PAYMENTS_HOST })
      const paymentFromOrder = await CSCPaymentPortal.getPayment({
        identity,
        paymentId,
      })

      if (!paymentFromOrder.paymentMethodId) {
        throw new Error('No paymentMethodId associated with payment')
      }

      const method = await CSCPaymentPortal.getPaymentMethod({
        identity,
        paymentMethodId: paymentFromOrder.paymentMethodId,
      })

      log('Payment method retrieved')
      const total = paymentFromOrder?.totalAmount || ''
      const last4 = method?.meta?.last4 || ''
      const type = method?.meta?.type || ''
      if (!!total && !!last4 && !!type) {
        return {
          method: paymentMethod,
          amount: `${defaultCurrencyCode} ${formatMoney(total)}`,
          type,
          mask: `************${last4}`,
          instrument: {
            ...standardizeCardFromCscPayments(method, 0),
            walletOrigin: 'csc',
          },
        }
      }
    } catch (err) {
      error(`Failed to retrieve customer payment for the order: ${err}`, {
        paymentId,
        paymentMethod,
        defaultCurrencyCode,
        orderTotal,
        err,
      })
    }
  }
  log('Returning transaction details for a guest ')
  return {
    method: paymentMethod,
    amount: `${defaultCurrencyCode} ${formatMoney(orderTotal)}`,
    type: '',
    mask: '',
    instrument: null,
  }
}

export default getPaymentMethodFromTransactionId
