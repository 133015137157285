import Axios from 'axios'

import { CSC_PAYMENTS_SHOP, CSC_SHOPFRONT_API, PARKY_API } from '@/services/Configuration'
import roundToNDigits from '@/helpers/roundToNDigits'
import XsrfResilient from '@/services/XsrfResilient'
import { goToCart } from '@/services/FramedCheckout/navigationServices'

import {
  PARKY_CREDIT_CARD_TRANSACTION_METHOD,
  PARKY_PAYPAL_TRANSACTION_METHOD,
  BOPUS_SHIPPING_DESCRIPTION,
  SHIPPED_SHIELD_LOGO,
} from '@/data/constants'

import { log, error } from '@/services/Log'

import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { standardizeOrder } from '@/services/Standardizers/checkout/standardizeOrder'
import { augmentCustomField } from '@/helpers/augmentCustomField'
import { standardizeCart } from '@/services/Standardizers/checkout/standardizeCart'
import { generateLineItemsFromCheckout } from '@/services/FramedCheckout/orderServices/generateLineItemsFromCheckout'

import { checkIsShippedShieldSku } from '@/helpers/checkIsShippedShieldItem'
import { getSavedInstrumentsCredentials } from '@/services/FramedCheckout/paymentServices/cscPaymentsServices/getSavedInstrumentsCredentials'
import { getPaymentMethodFromTransactionId } from '@/services/FramedCheckout/orderServices/getPaymentMethodFromTransactionId'
import { mapStateToAcronym } from '@/helpers/stateNameAcronymMapping'

import type { ParkyOrderResponse, ParkyOrderResponseData, ParkyShipmentInfo } from '@/types/Order'
import type { OrderProduct, ShopOrder } from '@csc/csc-sdk/dist/shopfront/order.type'
import type { Product, SavedInstrument } from '@/types'
import type { StandardAddress } from '@/types/ShopFront/CheckoutStandards'
import { isExtendSku } from '@/helpers/checkIsExtendItem'

const getShippingAddressFromShopOrder = (order: ShopOrder) => ({
  ...(
    order
      ?.shippingAddresses
      ?.find?.(({ shippingMethod }) => shippingMethod !== BOPUS_SHIPPING_DESCRIPTION)
    || {}
  ),
  customerId: order?.customerId,
})

type MinimumLineItemProps = {
  categories: number[]
  discountAmount: number
  discounts: { discountAmount: number }[]
  extendedComparisonPrice: number
  extendedListPrice: number
  extendedSalePrice: number
  giftWrapping: null
  id: number
  imageUrl: string
  isTaxable: boolean
  listPrice: number
  name: string
  options: unknown[]
  productId: number
  quantity: number
  salePrice: number
  sku: string
  type: string
  url: string
  socialMedia: unknown[]
  variantId: number
}

const getTotalDiscountOnParkyProduct = <T extends OrderProduct>(product: T) => (
  product?.appliedDiscounts?.reduce?.((acc, { amount }) => acc + parseFloat(amount), 0)
)

const handleTiltCharOnName = (name: string) => (
  name.split('~')[0]
)
const allLowercase = (str: string) => String(str).toLowerCase()
const removeSpaces = (str: string) => String(str).replace(/\s/g, '-')
const trim = (str: string) => String(str).replace(/^(\s|-)+|(\s|-)+$/g, '')
const removeDuplicatedDashes = (str: string) => String(str).replace(/-+/g, '-')

const nameToSlug = (name: string) => (
  removeDuplicatedDashes(trim(removeSpaces(allLowercase(handleTiltCharOnName(name)))))
)

type MaybeEnhancedProduct = OrderProduct & {
  imageUrl: string
  primarySku?: string | undefined
  customUrl?: string | undefined
}

const maybeAddSkuToUrl = (url: string, sku: string) => (
  url.includes(sku)
    ? url
    : `${url}${url.includes('?') ? '&' : '?'}sku=${sku}`
)
const getProductUrl = (product: MaybeEnhancedProduct) => (
  product?.customUrl
    ? maybeAddSkuToUrl(product?.customUrl, product?.sku)
    : `/p-${nameToSlug(product?.name)}-${product?.primarySku || product?.sku}`
)

const getImageUrlFromProductVariant = (sku: string) => (product: Product) => (
  product?.variants?.find?.(({ sku: variantSku }) => variantSku === sku)?.image_url
  || product?.images?.[0]?.url_standard
  || String(product?.image_url)
  || ''
)

const maybeFetchExtraInfoFromTheProduct = async (
  product: OrderProduct,
): Promise<MaybeEnhancedProduct> => {
  try {
    if (checkIsShippedShieldSku(product?.sku) || isExtendSku(product?.sku)) {
      return {
        ...product,
        imageUrl: SHIPPED_SHIELD_LOGO,
      }
    }

    const [{ bySku }] = await allPromisesWithRetries(() => [
      import('@/services/Product/bySku'),
    ])
    const [extraData] = await bySku({
      sku: product?.sku,
    })
    return {
      ...product,
      imageUrl: getImageUrlFromProductVariant(product?.sku)(extraData),
      primarySku: extraData?.sku,
      customUrl: extraData?.custom_url?.url,
    }
  } catch (err) {
    error('Error fetching extra data from the product', err)
  }
  return {
    ...product,
    imageUrl: '',
  }
}

const getShipmentWithProduct = (
  shipmentTracking: ParkyShipmentInfo[],
) => (product: MaybeEnhancedProduct) => (
  shipmentTracking.find((shipment) => (
    shipment?.items?.some?.((item) => item.order_product_id === product?.id)
  ))
)

const isUPS = (shippingMethod: string) => (
  String(shippingMethod).toLowerCase()?.includes?.('ups')
  || String(shippingMethod).toLowerCase()?.includes?.('united parcel service')
)

export const fixWordingForBusiness = (shippingMethod: string) => (
  isUPS(shippingMethod)
    ? 'UPS'
    : shippingMethod
)
type MinShipmentInfo = Pick<ParkyShipmentInfo, 'tracking_link' | 'shipping_method' | 'tracking_number' | 'tracking_link'>
const toShipmentTrackingMessage = (shipment?: MinShipmentInfo) => (
  shipment?.tracking_number
    ? `${fixWordingForBusiness(shipment.shipping_method).trim()} - ${String(shipment.tracking_number).trim()}`
    : ''
)

export const prepareMessageFromShipment = (shipment?: MinShipmentInfo) => ({
  shipmentTrackingMessage: toShipmentTrackingMessage(shipment),
  shipmentTrackingLink: shipment?.tracking_link || '',
})

// TODO: Replace image_url with the image from the product search API
const ParkyProductToMinimumLineItem = (
  shipmentTracking: ParkyShipmentInfo[],
) => (product: MaybeEnhancedProduct): MinimumLineItemProps => ({
  categories: [],
  discountAmount: getTotalDiscountOnParkyProduct(product),
  discounts: [],
  extendedComparisonPrice: parseFloat(product?.totalExcludingTax),
  extendedListPrice: parseFloat(product?.totalExcludingTax),
  extendedSalePrice: parseFloat(product?.totalExcludingTax),
  giftWrapping: null,
  id: product?.id,
  // TODO: Replace image_url with the image from the product search API
  imageUrl: product?.imageUrl,
  isTaxable: true,
  listPrice: parseFloat(product?.totalExcludingTax) / product?.quantity,
  name: product?.name,
  options: [...product.productOptions],
  productId: product?.productId,
  quantity: product?.quantity,
  salePrice: parseFloat(product?.totalExcludingTax) / product?.quantity,
  socialMedia: [],
  sku: product?.sku,
  type: 'physical',
  url: getProductUrl(product),
  variantId: product?.variantId,
  ...prepareMessageFromShipment(getShipmentWithProduct(shipmentTracking)(product)),
})

const createStandardizedShippingOptionFromShipment = (parkyShipment: {
  shippingMethod: string
  baseCost: string
}) => ({
  id: 0,
  type: 'shipping_carrier_118',
  description: parkyShipment.shippingMethod,
  imageUrl: '',
  cost: parseFloat(parkyShipment?.baseCost),
  transitTime: '',
  additionalDescription: '',
  isRecommended: true,
})

const productBelongsToShipment = (shipmentId: number | string) => (product: OrderProduct) => (
  String(product?.orderAddressId) === String(shipmentId)
)

const productLineItemFromAugmentedProductPromise = (
  shipmentTracking: ParkyShipmentInfo[],
) => (productPromise: Promise<MaybeEnhancedProduct>) => (
  productPromise.then(ParkyProductToMinimumLineItem(shipmentTracking))
)

const USOrUnitedStatesPattern = /US|United States/i
const isUSOrUnitedStates = (country: string) => USOrUnitedStatesPattern.test(country)
const countryCodeFromCountry = (country: string) => (
  isUSOrUnitedStates(country)
    ? 'US'
    : country
)

const standardizeAddressFromShopOrder = (
  address: ShopOrder['billingAddress'] | ShopOrder['shippingAddresses'][0],
): StandardAddress => ({
  street1: address.street1,
  street2: address.street2,
  city: address.city,
  stateAcronym: mapStateToAcronym(address.state),
  zip: address.zip,
  country: address.country,
  firstName: address.firstName,
  lastName: address.lastName,
  phone: address.phone,
  email: address.email,
  company: address.company,
  countryCode: countryCodeFromCountry(address.country),
  addressType: 'residential',
  shouldSaveAddress: false,
  stateName: address.state,
  customFields: address.formFields.map(augmentCustomField),
})

const getProductsFromParkyShipment = (
  shipmentTracking: ParkyShipmentInfo[],
) => (shipmentId: number | string) => (order: ShopOrder) => (
  Promise.all(
    order?.products
      ?.filter?.(productBelongsToShipment(shipmentId))
      ?.map?.(maybeFetchExtraInfoFromTheProduct)
      ?.map(productLineItemFromAugmentedProductPromise(shipmentTracking)),
  )
)

const getIdFromParkyProduct = <T>(product: { id: T }) => product?.id

const getConsignmentsFromOrderAndShopOrder = (
  shipmentTracking: ParkyShipmentInfo[],
) => async (order: ShopOrder) => (
  Promise.all(
    order?.shippingAddresses?.map?.(async (shipment) => ({
      id: shipment?.id,
      shippingCost: shipment?.baseCost,
      handlingCost: shipment?.baseHandlingCost,
      couponDiscounts: [],
      discounts: [],
      lineItemIds: (
        await getProductsFromParkyShipment(shipmentTracking)(shipment.id)(order)
      )?.map(getIdFromParkyProduct),
      selectedShippingOption: createStandardizedShippingOptionFromShipment(shipment),
      shippingAddress: {
        ...standardizeAddressFromShopOrder(shipment),
        customerId: order.customerId,
      },
      availableShippingOptions: [createStandardizedShippingOptionFromShipment(shipment)],
      lineItems: await getProductsFromParkyShipment(shipmentTracking)(shipment.id)(order),
      isBopus: shipment.shippingMethod === BOPUS_SHIPPING_DESCRIPTION,
    })),
  )
)

type Transaction = {
  mask: string,
  method: string,
  instrument: null | SavedInstrument,
}

const appendLeadingZeroes = (n: string): string => (
  n.length < 4 ? appendLeadingZeroes(`0${n}`) : n
)

const parsedMask = (mask: string) => appendLeadingZeroes(parseInt(mask.slice(-4), 10).toString())

const getTransactionMaskDigits = (transaction: Transaction) => (
  transaction?.mask
    ? parsedMask(transaction?.mask)
    : transaction?.mask
)

const standardizePaymentMethodMessage = (transaction: Transaction) => ((
  transaction?.method === PARKY_CREDIT_CARD_TRANSACTION_METHOD
  && Number.isInteger(parseInt(getTransactionMaskDigits(transaction)))
  && `Credit Card - Ending in ${getTransactionMaskDigits(transaction)}`
) || (
  transaction?.method === PARKY_PAYPAL_TRANSACTION_METHOD
    && 'PayPal'
)
  || transaction?.method
)

const standardizePaymentMethod = (transaction: Transaction) => ({
  messageRendered: standardizePaymentMethodMessage(transaction),
  ...transaction,
})

type BCZipHolder = { billingAddress: { postalCode: string } }
const unpackZipFromBCZipHolder = async (bcZipHolder: Promise<{ data: BCZipHolder }>) => (
  (await bcZipHolder)?.data?.billingAddress?.postalCode
)
// http://localhost:5000/checkout/order-confirmation/757260?zip=12205
// Returns Order data. - https://developer.bigcommerce.com/api-reference/storefront/orders/order/ordersbyorderidget
// This will return order data immediately after an order is placed on the storefront.
const getZipFromBCStoreFrontAPI = async (orderId: string) => unpackZipFromBCZipHolder(
  Axios.get<BCZipHolder>(`/api/storefront/orders/${orderId}`),
)

const getZipFromQueryParams = () => (
  (new URLSearchParams(window.location.search)).get('zip')
)

const getZip = async (orderId: string) => (
  getZipFromQueryParams() || await getZipFromBCStoreFrontAPI(orderId)
)

const fetchParkyOrderWithIdAndZip = async (orderId: string, zip: string) => (
  // GET {parky_url}/sf/order/{order-id}/{zip-code}/
  (await Axios.get<ParkyOrderResponse>(`${PARKY_API}/sf/order/${orderId}/${zip}/`))?.data?.data
)

const fetchParkyOrder = async (orderId: string) => (
  fetchParkyOrderWithIdAndZip(orderId, await getZip(orderId))
)

const ZGoldStaffNotePrefix = 'Z Gold used: $'
const hasZgoldStaffNotes = (staffNotes: string) => (
  staffNotes?.startsWith?.(ZGoldStaffNotePrefix)
)

const getStoreCreditFromShopOrder = (
  { staffNotes, storeCreditAmount }: { staffNotes: string, storeCreditAmount: string },
) => (
  hasZgoldStaffNotes(staffNotes)
    ? roundToNDigits(String(staffNotes?.split?.(ZGoldStaffNotePrefix)?.pop()))
    : parseFloat(storeCreditAmount)
)

const getTotalFromShopOrder = (order: ShopOrder) => (
  roundToNDigits(
    parseFloat(order?.subtotalIncludingTax) + parseFloat(order?.shippingCostIncludingTax),
  )
)
const getOutstandingBalance = (order: ShopOrder) => (
  getStoreCreditFromShopOrder(order) === getTotalFromShopOrder(order)
    ? 0
    : parseFloat(order?.totalIncludingTax)
)

type CouponForSoreFront = {
  code: string,
  discountedAmount: number,
  displayName: string,
  id: number,
  couponType: string,
}

const couponTypeMap: { [key: number]: string | undefined } = {
  0: 'per_item_discount',
  1: 'percentage_discount',
  2: 'per_total_discount',
  3: 'shipping_discount',
  4: 'free_shipping',
  5: 'promotion',
}

const orderCouponsToStoreFrontCoupons = (order: ShopOrder): CouponForSoreFront[] => (
  order?.coupons?.map?.(({
    code,
    discount,
    id,
    type,
  }) => ({
    code,
    discountedAmount: parseFloat(discount),
    displayName: '',
    id,
    couponType: couponTypeMap[type] || '',
  }))
)

// https://developer.bigcommerce.com/docs/rest-management/orders/order-status
const orderStatusMap: { [key: number]: string | undefined } = {
  0: 'INCOMPLETE',
  1: 'PENDING',
  2: 'SHIPPED',
  3: 'PARTIALLY_SHIPPED',
  4: 'REFUNDED',
  5: 'CANCELLED',
  6: 'DECLINED',
  7: 'AWAITING_PAYMENT',
  8: 'AWAITING_PICKUP',
  9: 'AWAITING_SHIPMENT',
  10: 'COMPLETED',
  11: 'AWAITING_FULFILLMENT',
  12: 'MANUAL_VERIFICATION_REQUIRED',
  13: 'DISPUTED',
  14: 'PARTIALLY_REFUNDED',
}

const buildStandardizableOrderFromShopOrder = (shipmentTracking: ParkyShipmentInfo[]) => async ({
  transaction,
  order,
}: {
  transaction: Transaction,
  order: ShopOrder,
}) => ({
  orderId: order?.id,
  shippingCostTotal: parseFloat(order?.shippingCostExcludingTax),
  billingAddress: {
    ...standardizeAddressFromShopOrder(order?.billingAddress),
    customerId: order?.customerId,
  },
  shippingCostBeforeDiscount: parseFloat(order?.shippingCostExcludingTax),
  taxTotal: parseFloat(order?.totalTax),
  coupons: orderCouponsToStoreFrontCoupons(order),
  id: order?.id,
  cartId: order?.cartId,
  customerId: order?.customerId,
  email: order?.billingAddress?.email,
  shippingAddress: getShippingAddressFromShopOrder(order),
  consignments: await getConsignmentsFromOrderAndShopOrder(shipmentTracking)(order),
  subtotal: parseFloat(order?.subtotalExcludingTax),
  paymentMethod: standardizePaymentMethod(transaction),
  storeCreditDiscount: getStoreCreditFromShopOrder(order),
  isStoreCreditApplied: getStoreCreditFromShopOrder(order) > 0,
  grandTotal: parseFloat(order?.totalIncludingTax),
  outstandingBalance: getOutstandingBalance(order),
  status: orderStatusMap[order?.statusId],
})

const getCurrencyFromShopOrder = (order: ShopOrder) => ({
  code: order?.currencyCode,
  decimalPlaces: 2,
  name: 'US Dollars',
  symbol: '$',
})

const extrapolateRawCartFromOrderAndShopOrder = (
  shipmentTracking: ParkyShipmentInfo[],
) => async (order: ShopOrder) => ({
  id: order?.cartId,
  customerId: order?.customerId,
  email: order.billingAddress.email,
  currency: getCurrencyFromShopOrder(order),
  isTaxIncluded: false,
  baseAmount: parseFloat(order?.subtotalExcludingTax),
  discountAmount: parseFloat(order?.discountAmount),
  cartAmount: 0,
  coupons: orderCouponsToStoreFrontCoupons(order),
  discounts: [],
  lineItems: {
    physicalItems: await Promise.all(
      order?.products
        ?.map?.(maybeFetchExtraInfoFromTheProduct)
        ?.map(productLineItemFromAugmentedProductPromise(shipmentTracking)),
    ),
    digitalItems: [],
    giftCertificates: [],
    customItems: [],
  },
  createdTime: order?.dateCreated,
  updatedTime: order?.dateModified,
  locale: 'en',
})

const FallBackTransactionInformationWhenNotAvailable: Transaction = {
  mask: '0000',
  method: 'CREDIT_CARD',
  instrument: null,
}

const isEmptyTransactionObject = (transaction?: Pick<Transaction, 'mask' | 'method'>) => (
  !transaction?.mask && !transaction?.method
)

const getTransactionFromParky = (parkyPromise: Promise<ParkyOrderResponseData | null>) => (
  parkyPromise
    .then((response): Transaction => (
      isEmptyTransactionObject(response?.transaction)
        ? FallBackTransactionInformationWhenNotAvailable
        : {
          method: response?.transaction?.method || FallBackTransactionInformationWhenNotAvailable.method,
          mask: response?.transaction?.mask || FallBackTransactionInformationWhenNotAvailable.mask,
          instrument: null,
        }
    ))
)

const getShopFrontData = (orderId: string) => (
  allPromisesWithRetries(() => [
    import('@csc/csc-sdk'),
    getSavedInstrumentsCredentials(),
    getZip(orderId),
  ])
)

const getOrdrFromShopFrontApi = async (orderId: string) => (
  getShopFrontData(orderId)
    .then(([{ ShopFront }, credentials, billingAddressZip]) => (
      ShopFront({
        credentials,
        host: CSC_SHOPFRONT_API,
      }).getOrder({
        orderId,
        billingAddressZip,
        shopName: CSC_PAYMENTS_SHOP,
      })
    ))
)

const isBNPLFieldSetTrue = (field: { name: string; value: string }) => field.name === 'isBNPL' && field.value === 'true'
const isBNPLOrder = (order: ShopOrder) => (
  order?.billingAddress?.formFields?.find(isBNPLFieldSetTrue)
)

const citizensPayTransaction = {
  method: 'Citizens Pay',
  mask: '',
  instrument: null,
}

const getTransactionFromCSCPayments = async (order: ShopOrder): Promise<Transaction> => (
  isBNPLOrder(order)
    ? citizensPayTransaction
    : getPaymentMethodFromTransactionId(
      order.paymentProviderId,
      order.paymentMethod,
      order?.defaultCurrencyCode,
      order?.totalIncludingTax,
    )
)

const attemptToFetchtransactionFromParkyAndCSCPayments = (
  parkyPromise: Promise<ParkyOrderResponseData | null>,
) => (order: ShopOrder) => (
  Promise.allSettled([
    getTransactionFromCSCPayments(order),
    getTransactionFromParky(parkyPromise),
  ])
)

const hasTransactionInfo = (
  result: PromiseSettledResult<Transaction>,
): result is PromiseFulfilledResult<Transaction> => (
  result.status === 'fulfilled'
)

const findSettledTransaction = (results: PromiseSettledResult<Transaction | undefined>[]) => (
  results.find(hasTransactionInfo)?.value
  || FallBackTransactionInformationWhenNotAvailable
)

const getTransactionFromOrder = (
  parkyPromise: Promise<ParkyOrderResponseData | null>,
) => (order: ShopOrder) => (
  attemptToFetchtransactionFromParkyAndCSCPayments(parkyPromise)(order)
    .then(findSettledTransaction)
)

const getShipmentTrackingFromOrder = (
  parkyPromise: Promise<ParkyOrderResponseData | null>,
): Promise<ParkyShipmentInfo[]> => (
  parkyPromise
    .then((response) => response?.shipments || [])
    .catch(() => [])
)
type ReceivesOrderId = { orderId: string }
export const getFinalizedOrder = XsrfResilient(async ({ orderId }: ReceivesOrderId) => {
  try {
    log('getFinalizedOrder for ', orderId)

    const shopOrder = await getOrdrFromShopFrontApi(orderId)
    const parkyPromise = fetchParkyOrder(orderId)
    const transaction = await getTransactionFromOrder(parkyPromise)(shopOrder)
    const shipmentTracking = await getShipmentTrackingFromOrder(parkyPromise)

    const order = await buildStandardizableOrderFromShopOrder(shipmentTracking)({
      transaction,
      order: shopOrder,
    })
    const rawCart = await extrapolateRawCartFromOrderAndShopOrder(shipmentTracking)(shopOrder)
    const lineItems = await generateLineItemsFromCheckout(rawCart)
    const cart = standardizeCart({
      ...rawCart,
      lineItems,
    })
    const response = standardizeOrder({
      ...order,
      orderId: String(order.orderId || shopOrder?.id || orderId),
      status: String(order.status || orderStatusMap[0]),
    }, cart)
    log('getFinalizedOrder END ', { newOrder: response, shopOrder, order, instrument: transaction.instrument })
    return {
      ...response,
      instrument: transaction.instrument,
    }
  } catch (err) {
    error(`getFinalizedOrder Failed to request API error: ${err}`, { error: err })
  }
  await goToCart()
  return null
})

export default getFinalizedOrder
