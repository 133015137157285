import Axios from 'axios'

export const canCreateCustomer = async (orderId: string | number) => (
  Axios.get<{ customerCanBeCreated: boolean }>(`/api/storefront/order/${orderId}`)
    .then(({ data: { customerCanBeCreated } }) => (
      !!customerCanBeCreated
    ))
    .catch(() => false)
)

export default canCreateCustomer
