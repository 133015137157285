import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { isLoyaltyCookieEnabled } from '@/services/FramedCheckout/loyaltyServices/isLoyaltyCookieEnabled'
import type { LoyaltyOffer } from '@/services/FramedCheckout/loyaltyServices/buildLoyaltyOffer'
import { enrollCustomerOnLoyaltyProgramSSR, toShopTokenType } from '@/services/FramedCheckout/loyaltyServices/enrollCustomerOnLoyaltyProgramSSR'
import { log } from '@/services/Log'
import type { LoyaltyOfferForSFAPI } from './buildLoyaltyOfferForSFAPI'

type ShowLoyaltyOfferEvent = Event & { offer?: LoyaltyOffer }
const clientSideEnrollmentFlow = (offer: LoyaltyOffer) => {
  const displayEvent: ShowLoyaltyOfferEvent = new Event('displayLoyaltyProgramSignup')
  displayEvent.offer = offer
  document.dispatchEvent(displayEvent)
  return { success: true }
}

export const enrollToShoptokenViaSFAPI = (offer: LoyaltyOfferForSFAPI) => {
  if (!window.shoptoken) {
    throw new Error('window.shoptoken is not defined')
  }
  const showOfferPayload = {
    orderId: offer.order.orderId,
    email: offer.order.billingAddress.email || offer.order.cart.email,
    paymentId: offer.instrument.last4,
    paymentType: toShopTokenType(offer.instrument.brand),
    onOfferAccepted: async () => enrollCustomerOnLoyaltyProgramSSR(offer),
    onOfferDeclined: () => {
      log('Shoptoken UI triggered onOfferDeclined')
    },
    onBannerClick: () => {
      log('Shoptoken UI triggered onBannerClick')
    },
    onWelcomeCTAClick: () => {
      log('Shoptoken UI triggered onWelcomeCTAClick')
    },
  }
  log(`Shoptoken UI triggered window.shoptoken.showOffer(${JSON.stringify(showOfferPayload)})`)
  window.shoptoken.showOffer(showOfferPayload)
}

export function displayLoyaltyProgramSignup(offer: LoyaltyOffer) {
  if (!isLoyaltyCookieEnabled() && !isFeatureEnabled('isLoyaltyProgramEnabled')) {
    return { success: false }
  }
  if (isFeatureEnabled('enrollToShoptokenViaSFAPI')) {
    return enrollToShoptokenViaSFAPI(offer)
  }
  return clientSideEnrollmentFlow(offer)
}

export default displayLoyaltyProgramSignup
