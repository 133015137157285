import { SHOP_ORIGIN } from '@/services/Configuration'
import { error, log } from '@/services/Log'
import type { SavedInstrument } from '@/types'
import type { StandardOrderWithId } from '@/types/ShopFront/CheckoutStandards'
import type { CscSessionCredentials } from '@csc/csc-sdk'
import { canCreateCustomer } from '@/services/Account/canCreateCustomer'

export type LoyaltyOfferForSFAPI = {
  logoUrl: string
  termsAndConditionsUrl: string
  coinName: string
  payment: {
    paymentId: string
    paymentType: string
  }
  customerEmail: string
  customerLastName: string
  customerFirstName: string
  order: StandardOrderWithId
  instrument: SavedInstrument
  canCreateCustomer: boolean
  credentials: CscSessionCredentials
}

export const buildLoyaltyOfferForSFAPI = async ({
  order,
  instrument,
  credentials,
}: {
  order: StandardOrderWithId,
  instrument: SavedInstrument,
  credentials: CscSessionCredentials,
}): Promise<null | LoyaltyOfferForSFAPI> => {
  try {
    if (!window.shoptoken) {
      log('No ShopToken SDK found')
      return null
    }
    const customerCanBeCreated = await canCreateCustomer(order.orderId)
    return {
      // Offer props from ShopFront
      logoUrl: `${SHOP_ORIGIN}/__statics/images/z-rewards-logo.svg`,
      termsAndConditionsUrl: `${SHOP_ORIGIN}/t-z-rewards-terms-and-conditions`,
      coinName: 'Z Gold',
      payment: {
        paymentId: instrument.last4,
        paymentType: instrument.brand,
      },
      customerEmail: order.cart.email,
      customerLastName: order.billingAddress.lastName,
      customerFirstName: order.billingAddress.firstName,
      // propagate order and instrument
      order,
      instrument,
      credentials,
      canCreateCustomer: customerCanBeCreated,
    }
  } catch (err) {
    error('Error getting loyalty program sign up info', err)
  }
  return null
}

export default buildLoyaltyOfferForSFAPI
