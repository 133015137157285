import desktopLogoSvg from '@/statics/images/zgallerie-logo.svg'
import mobileLogoSvg from '@/statics/images/zgallerie-mobile-logo.svg'
import zgoldLogo from '@/statics/images/zgold-logo.svg'
import { getCookieByName } from '@/helpers/common'
import {
  FEATURE_TOGGLES, SHOP_HASH, SHIPPEDSHIELD_EXPERIMENT_ID, BOPUS_FIELD,
} from '@/services/Configuration'
import { error, log } from '@/services/Log'
import { getExperiment } from '@/helpers/getOptimizeExperiment'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { EXTEND_LOGO } from '@/helpers/checkoutHelpers'
import { EXTEND_SKU, SHIPPED_SHIELD_SKU } from '@/data/constants'
import type { FramedCheckoutContext } from '@/services/FramedCheckout/'

// TODO: This should be passed as env_var
const LISTRAK_ID = 'EmCC5iEfX18F'

const getIsLoyaltyProgramEnabled = () => {
  const { isLoyaltyProgramEnabled } = FEATURE_TOGGLES
  try {
    const isLoyaltyCookieEnabled = getCookieByName('enableRewards') === 'true'
    return isLoyaltyProgramEnabled || isLoyaltyCookieEnabled
  } catch (e) {
    log(e)
  }
  // fallback
  return isLoyaltyProgramEnabled
}

const cybersourceApplePayEnabled = () => (
  isFeatureEnabled('miniCartApplePayCtaEnabled')
  && isFeatureEnabled('applePayEnabled')
) || isFeatureEnabled('forceApplePayOn')

const canUseApplePayFromCybersource = () => (
  cybersourceApplePayEnabled()
      && typeof ApplePaySession !== 'undefined'
      && ApplePaySession?.canMakePayments?.()
)

const isStripeCheckoutApplePayEnabled = () => (
  isFeatureEnabled('isStripeApplePayEnabled')
  && isFeatureEnabled('isStripeCheckoutApplePayEnabled')
) || isFeatureEnabled('forceApplePayOn')

const getShippedShieldExperimentToggled = (): Promise<boolean> => (isFeatureEnabled('forceShippedShieldOn')
  ? Promise.resolve(true)
  : (
    getExperiment({ experimentId: SHIPPEDSHIELD_EXPERIMENT_ID })
      .then((experiment) => (experiment === '1')))
    .catch((e) => {
      error(`getExperiment failed with error: ${String(e)}`, e)
      return false
    })
)

const canUseApplePayFromStripe = async (context: FramedCheckoutContext) => {
  try {
    if (isStripeCheckoutApplePayEnabled()) {
      const result = await context.stripeApplePayRequest?.canMakePayment()
      return !!result?.applePay
    }
  } catch (err) {
    error(error)
  }
  return false
}

export const getBranding = (context: FramedCheckoutContext) => async () => {
  const canUseApplePay = (
    (await canUseApplePayFromStripe(context))
    || (canUseApplePayFromCybersource())
  )
  return {
    brandName: 'ZGallerie',
    logoUrl: 'https://cdn11.bigcommerce.com/s-whvhnri10h/content/images/zgallerie-logo.svg',
    themeName: 'zg',
    desktopLogoSvg,
    mobileLogoSvg,
    listrak: LISTRAK_ID,
    extendSku: EXTEND_SKU,
    extendLogo: EXTEND_LOGO,
    shippedShieldSku: SHIPPED_SHIELD_SKU,
    zgoldLogo,
    FEATURE_TOGGLES: {
      ...FEATURE_TOGGLES,
      isLoyaltyProgramEnabled: getIsLoyaltyProgramEnabled(),
    } ?? {},
    canUseApplePay,
    storeHash: SHOP_HASH,
    bopusField: BOPUS_FIELD,
    /*
      for theme-zg vs theme-okl, eventually allow client to
      provide JSON object with primary, secondary, etc. colors
      and font choices.
    */
    shippedShieldExperimentToggled: await getShippedShieldExperimentToggled(),
  }
}

export default getBranding
