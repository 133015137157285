import {
  BOPUS_FIELD,
  BOPUS_STORE_FIELD,
  RESERVATION_NUMBER_FIELD,
  DTP_NUMBER_FIELD,
  CHECKOUT_VERSION_FIELD,
  STORE_CREDITS_APPLIED_FIELD,
  IS_CITIZENS_PAY_ORDER_FIELD,
  CATEGORY_PAGE_VERSION_FIELD,
  ZGOLD_REDEEMED_FIELD,
} from '@/services/Configuration'

// Access /api/storefront/form-fields
// IE https://staging-shop.zgallerie.com/api/storefront/form-fields

const fields: [string, unknown][] = [
  ['BopusStoreId', BOPUS_STORE_FIELD],
  ['CATEGORY PAGE VERSION', CATEGORY_PAGE_VERSION_FIELD],
  ['IsBopus', BOPUS_FIELD],
  ['ReservationNumber', RESERVATION_NUMBER_FIELD],
  ['DesignTradeGroup', DTP_NUMBER_FIELD],
  ['CheckoutVersion', CHECKOUT_VERSION_FIELD],
  ['StoreCreditApplied', STORE_CREDITS_APPLIED_FIELD],
  ['ZGoldRedeemed', ZGOLD_REDEEMED_FIELD],
  ['IsCitizensPayOrder', IS_CITIZENS_PAY_ORDER_FIELD],
]

const stringTuplesToMap = (tuples: [unknown, unknown][]): { [key: string]: string } => (
  tuples.reduce((acc, [key, value]) => ({
    ...acc,
    [String(key)]: String(value),
    [String(value)]: String(key),
  }), {})
)

type FieldIdNameMap = { [key: string]: string | undefined }

const fieldNameToIdMap: FieldIdNameMap = stringTuplesToMap(fields)

type FormField = {
  name: string
  value: string
  fieldId?: undefined
  fieldValue?: undefined
}

type CustomField = {
  fieldId: string
  fieldValue: string
  name?: undefined
  value?: undefined
}

export const augmentCustomField = (customField: FormField | CustomField): {
  fieldId: string
  name: string
  fieldValue: string
  value: string
} => ({
  fieldId: customField.fieldId || fieldNameToIdMap[`${customField.name || ''}`] || '',
  name: customField.name || fieldNameToIdMap[`${customField.fieldId || ''}`] || '',
  fieldValue: customField.fieldValue || customField.value || '',
  value: customField.fieldValue || customField.value || '',
})

export default augmentCustomField
