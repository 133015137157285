import Axios from 'axios'

import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import type { StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import { captureMessage, withScope } from '@sentry/nextjs'

type StandardOrderWithOrderId = StandardOrder & { orderId: string }
// TODO: !!WARNING!! finalizePendingOrder needs to be tested
export const finalizePendingOrder = async ({ standardOrder }: { standardOrder: StandardOrderWithOrderId }) => {
  const [
    { postOrderFlow },
    { getOrderWithPayments },
    { goToConfirmation },
  ] = await allPromisesWithRetries(() => [
    import('@/services/FramedCheckout/orderServices/postOrderFlow'),
    import('@/services/FramedCheckout/orderServices/getOrderWithPayments'),
    import('@/services/FramedCheckout/navigationServices'),
  ])
  const { orderId } = standardOrder
  const order = await getOrderWithPayments({ orderId })
  if (order.payments?.length) {
    const finalizedExternalPayments = order.payments.filter((payment) => payment?.detail?.step === 'ACKNOWLEDGE' && Number.isFinite(payment.amount) && payment.amount > 0)
    log('Finalized external payments', finalizedExternalPayments)
    const externallyPaidAmount = finalizedExternalPayments.reduce((paid, payment) => (
      paid + (payment.amount || 0)
    ), 0)
    log('externallyPaidAmount', externallyPaidAmount)

    log('is externally paid amount >= to grand total? ', externallyPaidAmount, order.grandTotal, externallyPaidAmount >= order.grandTotal)
    if (
      Number.isFinite(order.grandTotal)
      && order.grandTotal > 0
      && externallyPaidAmount >= order.grandTotal
    ) {
      log('from inside payment review and processing')
      try {
        // TODO: We need documentation regarding this API
        log('trying create order ', orderId)
        await Axios.post(`/internalapi/v1/checkout/order/${orderId}`)
        log('done creating order ', orderId)
      } catch (err) {
        if (err.type !== 'order_finalization_not_required') {
          log(err)
        }
      } finally {
        log('post transition order update')
        const postTransitionOrder = await getOrderWithPayments({ orderId })
        log('order after payment', postTransitionOrder)

        // Sentry log for post-payment flow
        withScope((scope) => {
          scope.setTag('conversion','purchase')
          scope.setContext('Data',
              {
                orderId: order?.orderId,
                paymentFlow: 'checkout-paypal',
                paymentStatus: postTransitionOrder?.status,
                method: 'finalizePendingOrder'
              }
          )
          captureMessage(
              `postPayment::finalizePendingOrder , orderId: ${orderId}`,
              'info',
          )
        })

        if (postTransitionOrder?.status !== 'INCOMPLETE') {
          log('going to confirmation for ', orderId)

          // Analytics event tracking
          try {
            await postOrderFlow({ order: standardOrder, paymentFlow: 'checkout-paypal' })
          } catch (e) {
            error('Error was caught during postOrderFlow: ', e)
          }
          goToConfirmation({
            orderId,
            zip: order?.billingAddress?.zip,
          })
          await new Promise((res) => {
            // Safari would continue script execution immediately, causing redirection
            // to cart.php if we don't await here.
            setTimeout(() => {
              res(true)
            }, 10000)
          })
        }
      }
    }
  }
}

export default finalizePendingOrder
