import type { SavedInstrument } from '@/types'
import type { StandardOrderWithId } from '@/types/ShopFront/CheckoutStandards'
import type { CscSessionCredentials } from '@csc/csc-sdk'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { canCreateCustomer } from '@/services/Account/canCreateCustomer'

export type LoyaltyOffer = {
  logoUrl: string
  termsAndConditionsUrl: string
  coinName: string
  payment: {
    paymentId: string
    paymentType: string
  }
  initialBonus: number
  customerEmail: string
  dynamicCashback: number
  additionalReward: number
  billingFrequency: 'year' | 'month'
  customerLastName: string
  customerFirstName: string
  loyaltyProgramCost: string
  loyaltyConversionRate: number
  orderAmountEligibleForCashback: number
  order: StandardOrderWithId
  instrument: SavedInstrument
  canCreateCustomer: boolean
  credentials: CscSessionCredentials
}

export const getOffer = async (order: StandardOrderWithId): Promise<{
  billingFrequency: 'year' | 'month',
  initialBonus: number
  dynamicCashback: number
  additionalReward: number
  loyaltyProgramCost: string
  loyaltyConversionRate: number
  orderAmountEligibleForCashback: number
  canCreateCustomer: boolean
}> => {
  if (!order.cart.customerId && !isFeatureEnabled('guestZgoldEnrollmentEnabled')) {
    throw new Error('Guest enrollment is not enabled')
  }
  const getSignupOfferPayload = (
    order?.cart?.customerId
      ? undefined
      : {
        email: order.cart.email,
        firstName: order.billingAddress.firstName,
        lastName: order.billingAddress.lastName,
        orderId: order.orderId,
      }
  )
  const [
    fromShopToken,
    customerCanBeCreated,
  ] = await Promise.all([
    window.shoptoken?.getSignUpOffer(getSignupOfferPayload),
    canCreateCustomer(order.orderId),
  ])
  if (!fromShopToken) {
    throw new Error('No loyalty program sign up offer found')
  }
  if (!order?.cart?.customerId && !customerCanBeCreated) {
    throw new Error('Customer cannot be created')
  }

  return {
    ...fromShopToken,
    canCreateCustomer: customerCanBeCreated,
  }
}

export default getOffer
