import { error, log } from '@/services/Log'
import { getSavedInstrumentsCredentials } from '@/services/FramedCheckout/paymentServices/cscPaymentsServices'
import Axios from 'axios'
import type { ShopTokenPaymentMethod } from '@/types/ThirdPartyIntegrations/ShopTokenInterface'
import {
  CSC_PAYMENTS_HOST, CSC_PAYMENTS_GATEWAY, CSC_SHOPFRONT_API, CSC_PAYMENTS_SHOP,
} from '@/services/Configuration'
import { ShopFront, Payments } from '@csc/csc-sdk'
import {
  captureException,
  withScope,
  captureMessage,
} from '@sentry/react'
import type { LoyaltyOfferForSFAPI } from '@/services/FramedCheckout/loyaltyServices/buildLoyaltyOfferForSFAPI'

const shopTokenTypemap: Array<{
  method: ShopTokenPaymentMethod
  pattern: RegExp
}> = [
  { pattern: /visa/gi, method: 'VISA' },
  { pattern: /mastercard/gi, method: 'MASTERCARD' },
  { pattern: /discover/gi, method: 'DISCOVER' },
  { pattern: /american express/gi, method: 'AMEX' },
  { pattern: /PAYPAL/gi, method: 'PAYPAL' },
  { pattern: /diners club/gi, method: 'DINERS_CLUB' },
  { pattern: /jcb/gi, method: 'JCB' },
  { pattern: /DANKORT/gi, method: 'DANKORT' },
  { pattern: /MAESTRO/gi, method: 'MAESTRO' },
  { pattern: /CARD/gi, method: 'STORED_CARD' },
]

export const toShopTokenType = (brand: string): ShopTokenPaymentMethod => (
  shopTokenTypemap.find(({ pattern }) => pattern.test(brand))?.method
  || 'STORED_CARD'
)

const tokenizeOfferInstrument = async (offer: LoyaltyOfferForSFAPI) => {
  try {
    const credentials = await getSavedInstrumentsCredentials()
    await Payments({
      // use guest credentials to copy the payment method
      credentials: offer.credentials,
      host: CSC_PAYMENTS_HOST,
    }).copyPaymentMethod({
      gatewayName: CSC_PAYMENTS_GATEWAY,
      paymentMethodId: offer.instrument.id,
      sourceIdentity: offer.credentials.identitySourceId,
      newOwnerIdentity: credentials.identitySourceId,
    })
  } catch (e) {
    error('Failed to tokenize card', e)
  }
}

type EnrollCustomerOnLoyaltyProgram = (offer: LoyaltyOfferForSFAPI) => Promise<void>

export const enrollCustomerOnLoyaltyProgramSSR: EnrollCustomerOnLoyaltyProgram = async (offer) => {
  const subscriptionOrderId = ''
  try {
    log('Enrolling customer on loyalty program', offer)
    if (!offer.order.orderId) {
      throw new Error('Order ID is not defined')
    }
    if (!window.shoptoken) {
      throw new Error('Shoptoken is not defined')
    }
    const shopfront = ShopFront({
      credentials: offer.credentials,
      host: CSC_SHOPFRONT_API,
    })
    log('Fetching presigned get order')
    const presignedGetOrder = await shopfront.presignGetOrder({
      billingAddressZip: String(offer.order.billingAddress.zip),
      orderId: String(offer.order.orderId),
      shopName: CSC_PAYMENTS_SHOP,
    })
    log('Enrolling customer on loyalty program', { offer, presignedGetOrder })
    const { loginUrl } = await shopfront.enrollLoyalty({
      shouldConvertGuestUserToRegistered: offer.canCreateCustomer,
      identity: offer.credentials.identitySourceId,
      originalOrderId: String(offer.order.orderId),
      shopName: CSC_PAYMENTS_SHOP,
      presignedGetOrder,
      paymentMethodId: offer.instrument.id,
    })
    if (offer.canCreateCustomer) {
      if (loginUrl) {
        try {
          await Axios.get(loginUrl)
        } catch (e) {
          error('Failed to create customer', e)
        }
      }
      try {
        await tokenizeOfferInstrument(offer)
        captureMessage(
          'SHOPTOKEN Enrollment of Guest SUCCEEDED on the SDK',
          'info',
        )
      } catch (e) {
        error('Failed to tokenize card', e)
        throw e
      }
    } else {
      log('SHOPTOKEN Enrollment of User SUCCEEDED on the SDK ')
      captureMessage(
        'SHOPTOKEN Enrollment of User SUCCEEDED on the SDK',
        'info',
      )
    }
  } catch (e) {
    error('Failed to enroll customer on loyalty program', e)
    withScope((scope) => {
      scope.setContext('subscribeCustomerInput', {
        email: (
          offer.order?.cart?.customerId
            ? undefined
            : offer.order.cart.email
        ),
        previousOrderId: String(offer.order.orderId), // the order id in the confirmation page
        subscriptionOrderId, // the order id created by our endpoint
        subscriptionOrderID: subscriptionOrderId,
        payment: {
          paymentId: offer.instrument.last4,
          paymentType: toShopTokenType(offer.instrument.brand),
        },
      })
    })
    captureException(e)
    throw e
  }
}

export default enrollCustomerOnLoyaltyProgramSSR
