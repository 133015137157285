import { ENVIRONMENT_NAME, SEARCH_API_V2 } from '@/services/Configuration'

import { log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import checkIsShippedShieldItem from '@/helpers/checkIsShippedShieldItem'

const unique = (list) => Object.keys(list.reduce((hash, key) => ({ ...hash, [key]: true }), {}))

const SKUS_ALWAYS_AVAILABLE = ['990000007', '999999250', '999999936']
type SkuHolder = { sku: string }
type VariantHolder = { variants: SkuHolder[], sku: string }
const mapAProductVariantSkusToItsData = <T, K extends SkuHolder>(data: T) => (key: keyof T) => (variants: K[] = []) => (
  variants.reduce((variantSkuToDataMap, v) => ({
    ...variantSkuToDataMap,
    [String(v?.sku)]: data[key],
  }), {})
)

const mapAProductIdAndItsSkusToItsData = <T extends { [key: string]: VariantHolder }>(data: T) => (
  Object.entries(data)
    .reduce((productSkuToDataMap, [id, p]) => ({
      ...productSkuToDataMap,
      [String(p?.sku)]: data[id],
      ...mapAProductVariantSkusToItsData(data)(id)(p?.variants || []),
    }), {})
)

export const generateLineItemsFromCheckout = async (cart) => {
  log('generateLineItemsFromCheckout START', { cart })
  const [
    { getProductInventory },
    { default: Axios },
    { chunkerizeArray },
    { EXTEND_LOGO, getSubtotalPrice },
    { EXTEND_SKU, SHIPPED_SHIELD_LOGO },
  ] = await allPromisesWithRetries(() => [
    import('@/services/Product/getProductInventory'),
    import('axios'),
    import('@/helpers/chunkerizeArray'),
    import('@/helpers/checkoutHelpers'),
    import('@/data/constants'),
  ])
  const lineItems = cart.lineItems.physicalItems
  const validIds = (
    lineItems
      .map((item) => item.productId)
      .filter((id) => !!id)
      .reduce((acc, id) => (
        (typeof id === 'string' || typeof id === 'number') && !!id
          ? [...acc, String(id)]
          : acc
      ), [])
  )
  const productIdsPerRequest = chunkerizeArray(validIds, 25)

  const Source = ['id', 'data.shipping_classification', 'data.name'].join(',')
  // TODO: We need documentation regarding this API
  const requests = await Promise.all(
    productIdsPerRequest.map((ids) => Axios.get(`${SEARCH_API_V2}/product`, { params: { ids: unique(ids).join(','), Source } })),
  )
  const itemsMetadata = requests.reduce((metadata, { data }) => ({
    ...metadata,
    // This extract the map from id to product data
    ...data,
    // Map all skus on data.variants to the data object
    ...mapAProductIdAndItsSkusToItsData(data),
  }), {})

  const ungroupedItems = await Promise.all(lineItems.map(async (lineItem) => {
    // get the original price before sale
    let originalPrice
    const itemMetadata = itemsMetadata[lineItem.productId]
    if (checkIsShippedShieldItem({ sku: lineItem.sku })) {
      originalPrice = lineItem.salePrice
      lineItem.imageUrl = SHIPPED_SHIELD_LOGO
      lineItem.subtotalPrice = getSubtotalPrice({
        price: lineItem.salePrice, quantity: lineItem.quantity,
      })
    } else if (!lineItem.sku.includes(EXTEND_SKU)) {
      originalPrice = itemMetadata?.variants.find((v) => v.sku === lineItem.sku)?.price
    } else {
      originalPrice = lineItem.salePrice
      lineItem.imageUrl = EXTEND_LOGO
      lineItem.subtotalPrice = getSubtotalPrice({
        price: lineItem.salePrice, quantity: lineItem.quantity,
      })
    }

    const isFurniture = (
      itemMetadata?.shipping_classification === 'furniture'
      // TODO: shipping_classification is returning unknown for staging but works in production
      // TODO: !! WARNING !! Temporary solution for the lack of furniture categorization on staging.
      // This should not impact production since it considers ENVIRONMENT_NAME
      || (
        ['production', 'production2'].indexOf(ENVIRONMENT_NAME) < 0
        && `${itemMetadata?.name}`.toUpperCase().indexOf('SOFA') >= 0
      )
    )

    const imageUrl = itemMetadata?.images?.[0]?.url_thumbnail || lineItem.imageUrl

    // for SKUs which are always available, skip the inventory check
    if (~SKUS_ALWAYS_AVAILABLE.indexOf(lineItem.sku) || lineItem.sku.includes(EXTEND_SKU)) {
      return {
        ...lineItem,
        imageUrl,
        originalPrice,
        subtotalPrice: getSubtotalPrice({ price: originalPrice, quantity: lineItem.quantity }),
        leadTime: 0,
        maxLeadTime: 0,
        availabilityMessage: 'Ready to Ship',
        isFurniture,
      }
    }

    const inventory = await getProductInventory({ sku: lineItem.sku })
    const availabilityMessage = (itemMetadata?.variants?.find((variant) => variant?.sku === lineItem.sku))?.shipping_message?.checkout
    return {
      ...lineItem,
      originalPrice,
      imageUrl,
      subtotalPrice: getSubtotalPrice({ price: originalPrice, quantity: lineItem.quantity }),
      leadTime: inventory.lead_time,
      maxLeadTime: inventory.lead_time_max,
      availabilityMessage,
      actualOverrideMessage: availabilityMessage,
      isFurniture,
    }
  }))

  const response = ungroupedItems.sort( // Sorting furniture to the end to match output from LineItemsFromCheckout
    (item1, item2) => (
      // eslint-disable-next-line no-nested-ternary
      (!item1.isFurniture && item2.isFurniture)
        ? -1
        : (item1.isFurniture && !item2.isFurniture)
          ? 1
          : 0
    ),
  )
  log('generateLineItemsFromCheckout END', { response })
  return response
}
