import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { debug } from '@/services/Log'
import type { CscSessionCredentials } from '@csc/csc-sdk'

// Here is where it all comes together
type CredentialsFlowOverride = {
  useGuest?: boolean
} | undefined
const defaultCredentialsFlow = { useGuest: false }
type GetSavedInstrumentsCredentials = (credentialsFlowOverride?: CredentialsFlowOverride) => Promise<CscSessionCredentials>
export const getSavedInstrumentsCredentials: GetSavedInstrumentsCredentials = async ({
  useGuest,
} = defaultCredentialsFlow) => {
  const [
    { getRegisteredUserCredentials },
    { getGuestUserCredentials },
  ] = await allPromisesWithRetries(() => [
    import('./getRegisteredUserCredentials'),
    import('./getGuestUserCredentials'),
  ])
  try {
    if(!useGuest){
      return await getRegisteredUserCredentials()
    }
  } catch (e) {
    // Might be a guest user
    debug('Failed to get registered user credentials', e)
  }
  debug('Using guest user credentials')
  return await getGuestUserCredentials()
}

export default getSavedInstrumentsCredentials
