import XsrfResilient from '@/services/XsrfResilient'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

import { log } from '@/services/Log'

export const getOrderWithPayments = XsrfResilient(async ({ orderId }) => {
  const [
    { default: Axios },
    { generateLineItemsFromCheckout },
    { standardizeOrderComplete },
  ] = await allPromisesWithRetries(() => [
    import('axios'),
    import('@/services/FramedCheckout/orderServices/generateLineItemsFromCheckout'),
    import('@/services/Standardizers'),
  ])
  log('getOrderWithPayments for ', orderId)
  const include = [
    'payments',
    'lineItems.physicalItems.socialMedia',
    'lineItems.physicalItems.options',
    'lineItems.digitalItems.socialMedia',
    'lineItems.digitalItems.options',
  ]
  const options = include?.length ? { params: { include: include.join(',') } } : undefined
  // Returns Order data. - https://developer.bigcommerce.com/api-reference/storefront/orders/order/ordersbyorderidget
  // This will return order data immediately after an order is placed on the storefront.
  const order = (await Axios.get(`/api/storefront/orders/${orderId}`, options)).data
  const lineItems = await generateLineItemsFromCheckout(order)
  lineItems.sort((a, b) => (a.sku > b.sku && 1) || -1)
  return standardizeOrderComplete(order, lineItems)
})
