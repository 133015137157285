import { goToCart } from '@/services/FramedCheckout/navigationServices'
import { getCustomer } from '@/services/FramedCheckout/customerServices/getCustomer'

import { getFinalizedOrder } from '@/services/FramedCheckout/orderServices/getFinalizedOrder'
import { getBranding } from '@/services/FramedCheckout/brandServices'

import { log, error } from '@/services/Log'
import { buildLoyaltyOffer } from '@/services/FramedCheckout/loyaltyServices/buildLoyaltyOffer'
import { buildLoyaltyOfferForSFAPI } from '@/services/FramedCheckout/loyaltyServices/buildLoyaltyOfferForSFAPI'
import { getSavedInstrumentsCredentials } from '@/services/FramedCheckout/paymentServices/cscPaymentsServices'
import { type FramedCheckoutContext } from '@/services/FramedCheckout/'
import { enrollToShoptokenViaSFAPI, displayLoyaltyProgramSignup } from '@/services/FramedCheckout/loyaltyServices/displayLoyaltyProgramSignup'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'

import type { StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import type { StandardOrderWithId } from '@/types/ShopFront/CheckoutStandards'
import type { CscSessionCredentials } from '@csc/csc-sdk'
import type { SavedInstrument } from '@/types'

const tryGetingCustomer = async (order: StandardOrder | null) => {
  if (order?.customerId) {
    try {
      if (window.shoptoken?.isCustomerInLoyaltyProgram?.() === true) {
        return {
          success: true,
          offer: null,
        }
      }
      return await getCustomer()
    } catch {
      // Not a valid returning customer
    }
  }
  return null
}

type LoyaltyFetchInput = {
  order: StandardOrderWithId,
  instrument: SavedInstrument | null,
  credentials: CscSessionCredentials,
}

const fetchLoyaltyOfferForSFAPI = async ({
  order,
  instrument,
  credentials,
}: LoyaltyFetchInput) => {
  if (!instrument) {
    return null
  }
  const offer = await buildLoyaltyOfferForSFAPI({
    order,
    instrument,
    credentials,
  })
  if(offer) {
    enrollToShoptokenViaSFAPI(offer)
  }
  return offer
}

const fetchLoyaltyOfferForClientSideFlow = async ({
  order,
  instrument,
  credentials,
}: LoyaltyFetchInput) => {
  if (!instrument) {
    return null
  }
  const offer = await buildLoyaltyOffer({
    order,
    instrument,
    credentials,
  })
  if(offer) {
    displayLoyaltyProgramSignup(offer)
  }
  return offer
}

const fetchLoyaltyOffer = (input: LoyaltyFetchInput) => {
  if(isFeatureEnabled('enrollToShoptokenViaSFAPI')) {
    return fetchLoyaltyOfferForSFAPI(input)
  }
  return fetchLoyaltyOfferForClientSideFlow(input)
}

export const initializeOrderConfirmation = (context: FramedCheckoutContext) => async () => {
  log('Initialize OrderConfirmationPage')
  try {
    const orderId = window?.location.pathname.split('/').pop()
    if (!orderId) {
      throw new Error('No orderId found in url')
    }
    const [
      order,
      branding,
    ] = await Promise.all([
      getFinalizedOrder({ orderId }),
      getBranding(context)(),
    ])
    if (!order) {
      throw new Error('No order found')
    }
    const orderInstrument = order.instrument
    const [
      customer,
      credentials,
    ] = await Promise.all([
      tryGetingCustomer(order),
      getSavedInstrumentsCredentials(),
    ])
    const offer = (
      fetchLoyaltyOffer({
          order: {
            ...order,
            orderId,
          },
          instrument: orderInstrument,
          credentials,
        })
    )
    return {
      success: true,
      savedInstruments: orderInstrument ? [orderInstrument] : [],
      order,
      branding,
      customer,
      offer,
    }
  } catch (err) {
    error(`Failed to initialize order confirmation  page:\t ${err}`, { error: err })
    await goToCart()
    return { success: false }
  }
}

export default initializeOrderConfirmation
