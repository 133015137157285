import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

import {
  CSC_IAM_GUEST_IDP,
  CSC_IAM_ACCOUNT,
  GUEST_TOKEN_NAME,
} from '@/services/Configuration'
import { debug } from '@/services/Log'

// This might be called server side and we don't want to use localStorage there.
const getItem = (key: string) => (
  typeof localStorage !== 'undefined' ? localStorage.getItem(key) : null
)
const setItem = (key: string, value: string) => (
  typeof localStorage !== 'undefined' ? localStorage.setItem(key, value) : null
)

export const getGuestTokenFromLocalStorage = async () => {
  let jwt: null | string = null
  const tokenOnStorage = getItem(GUEST_TOKEN_NAME)
  if (tokenOnStorage) {
  // If the token exists, assume you're good. If not, the implementer can clear the token and retry.
    jwt = tokenOnStorage
    debug('Using guest token from local storage')
  } else {
    debug('Getting guest token from IAM')
    const [{ IAM }] = await allPromisesWithRetries(() => [import('@csc/csc-sdk')])
    const { token } = await IAM().getAnonymousJwtIdpToken({
      accountId: CSC_IAM_ACCOUNT, jwtIdpName: CSC_IAM_GUEST_IDP,
    })
    jwt = token
  }
  debug('Saving guest token to local storage', { GUEST_TOKEN_NAME, jwt })
  setItem(GUEST_TOKEN_NAME, jwt)
  return jwt
}

export default getGuestTokenFromLocalStorage
